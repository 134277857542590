.footer {
  padding-top: 15px;
  height: 110px;
  width: 100%;
  background-color: #1b1c1d;
  text-align: center;
  color: white;
}
.footer a:first-of-type img {
  border: none;
  padding: 0 5px;
}
.footer img {
  height: 55px;
  display: inline-block;
  border-left: 1px solid dimgray;
}
.footer p {
  margin-top: 10px;
  color: #FFFFFF;
}
@media screen and (max-width: 414px) {
  .footer-logo {
    width: 100% !important;
  }
  .footer-logo-facebook {
    width: 5% !important;
  }
  .footer-logo-messenger {
    width: 6% !important;
  }
}